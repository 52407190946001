
import { Vue } from "vue-class-component";
import { ElLoading } from "element-plus";

export default class Loading extends Vue {
  loading = ElLoading.service({
    lock: true,
    text: "Loading...",
    spinner: "",
    background: "rgba(255, 255, 255, 255)",
  });

  created() {
    this.load();
  }

  load() {
    const that = this;
    const timer = setInterval(() => {
      if (document.readyState === "complete") {
        this.loading.close();
        window.clearInterval(timer);
      }
    }, 800);
  }
}
