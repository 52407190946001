import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "Naucy",
    },
    component: HomeView,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/HomePage.vue"),
      },
      {
        path: "/test",
        name: "test",
        component: () => import("@/views/AboutView.vue"),
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/AboutView.vue"),
  },
  {
    path: "/reg",
    name: "reg",
    component: () => import("@/views/RegisterView.vue"),
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("@/views/ChatView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/AdminView.vue"),
    children: [
      {
        path: "/admin/home",
        name: "adminHome",
        component: () => import("@/views/AdminHomePage.vue"),
      },
      {
        path: "/admin/db",
        name: "adminDB",
        component: () => import("@/views/AdminDBViewerPage.vue"),
      },
      {
        path: "/admin/log",
        name: "adminLogger",
        component: () => import("@/views/AdminLoggerPage.vue"),
      },
    ],
  },
  {
    path: "/protocol/license_service",
    name: "license_service",
    component: () => import("@/views/LicenseAndServiceAgreementView.vue"),
  },
  {
    path: "/protocol/user_privacy",
    name: "user_privacy",
    component: () => import("@/views/UserPrivacyAgreementView.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    name: "404",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
