
import { Vue } from "vue-class-component";
//import { ref } from "vue";
//import { ElMessage } from "element-plus";

interface LinkItem {
  value: string;
  link: string;
}

export default class HomwView extends Vue {
  aside = true;
  drawer = false;
  autocomplete_show = false;
  menuItemShow = true;
  state = "";
  autocompleteList: LinkItem[] = [];

  created() {
    var width = document.documentElement.clientWidth;
    console.log(width);
    if (width <= 800) {
      this.menuItemShow = false;
    }
  }

  querySearch(queryString: string, cb: any) {
    const results = queryString
      ? this.autocompleteList.filter((e) => {
          return e.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
        })
      : this.autocompleteList;
    cb(results);
  }

  handleSelect(item: LinkItem) {
    console.log(item);
  }

  handleIconClick(ev: Event) {
    console.log(ev);
  }

  setAutocomplete(show: boolean) {
    this.autocomplete_show = show;
  }

  setAutocompleteList(list: LinkItem[]) {
    this.autocompleteList = list;
    console.log(this.autocompleteList);
  }
}
